var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { "close-on-content-click": false, "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "blue font-weight-bold",
                      attrs: {
                        small: "",
                        loading: _vm.getPerformingLoadboardAction(_vm.loadId),
                        disabled: _vm.isLoadboardsDisabled,
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm._v(" Loadboards "),
                  _vm.hasFailedStatus
                    ? _c("v-icon", { attrs: { right: "", dark: "" } }, [
                        _vm._v(" mdi-alert-decagram "),
                      ])
                    : _vm._e(),
                  _vm.allSuccessStatus
                    ? _c("v-icon", { attrs: { right: "", dark: "" } }, [
                        _vm._v(" mdi-checkbox-marked-circle "),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.menu,
        callback: function ($$v) {
          _vm.menu = $$v
        },
        expression: "menu",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticStyle: { "background-color": "#1e88e5" } },
            [
              _c(
                "v-row",
                { staticClass: "align-center" },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("span", { staticClass: "text-h2 white--text" }, [
                      _vm._v("Manage Loadboards"),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-card-text", { staticClass: "pb-0 mb-2" }, [
            _c(
              "span",
              { staticClass: "loadboard-list" },
              [
                _c(
                  "span",
                  { staticClass: "mb-1" },
                  [
                    _c("span", [_vm._v(" ALL ")]),
                    _c("v-simple-checkbox", {
                      attrs: {
                        dense: "",
                        ripple: false,
                        value: _vm.allSelected,
                      },
                      on: { input: _vm.toggleAll },
                    }),
                    _c("span"),
                  ],
                  1
                ),
                _c("v-divider", { staticClass: "mb-1" }),
                _vm._l(_vm.loadboardState, function (loadboard) {
                  return _c(
                    "span",
                    { key: loadboard.name },
                    [
                      _c("span", [_c("b", [_vm._v(_vm._s(loadboard.name))])]),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "", disabled: loadboard.isEnabled },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-simple-checkbox",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              dense: "",
                                              ripple: false,
                                              disabled: !loadboard.isEnabled,
                                            },
                                            model: {
                                              value:
                                                _vm.selected[loadboard.name],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.selected,
                                                  loadboard.name,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selected[loadboard.name]",
                                            },
                                          },
                                          "v-simple-checkbox",
                                          attrs,
                                          false
                                        ),
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("Loadboard has been disabled")])]
                      ),
                      _c(
                        "span",
                        { class: _vm.getStatusClass(loadboard.status || "") },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: {
                                bottom: "",
                                disabled: !loadboard.latestError,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "b",
                                          _vm._g(
                                            _vm._b({}, "b", attrs, false),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              _vm._s(
                                                (
                                                  loadboard.status ||
                                                  "NOT_POSTED"
                                                ).replace(/_/g, " ")
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    loadboard.latestError &&
                                      loadboard.latestError.message
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
          ]),
          _c(
            "v-card-actions",
            { staticClass: "pt-0" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      _vm.menu = false
                    },
                  },
                },
                [_vm._v(" Close ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "red",
                    text: "",
                    disabled: _vm.isDeleteDisabled,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submit("DELETE")
                    },
                  },
                },
                [_vm._v(" Delete ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "info",
                    text: "",
                    disabled: _vm.isPostDisabled,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submit("POST")
                    },
                  },
                },
                [_vm._v(" Post ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }