import { render, staticRenderFns } from "./LoadboardManager.vue?vue&type=template&id=18663bd1&scoped=true&"
import script from "./LoadboardManager.vue?vue&type=script&lang=js&"
export * from "./LoadboardManager.vue?vue&type=script&lang=js&"
import style0 from "./LoadboardManager.vue?vue&type=style&index=0&id=18663bd1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18663bd1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/cuzox/projects/exo/Exo-TMS-UI/v1/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('18663bd1')) {
      api.createRecord('18663bd1', component.options)
    } else {
      api.reload('18663bd1', component.options)
    }
    module.hot.accept("./LoadboardManager.vue?vue&type=template&id=18663bd1&scoped=true&", function () {
      api.rerender('18663bd1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/components/LoadboardManager.vue"
export default component.exports